/* eslint-disable complexity, sonarjs/cognitive-complexity */
import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { withTheme } from 'styled-components'

import Animate from '@vfuk/core-animate'
import Container from '@vfuk/core-container'
import Overlay from '@vfuk/core-overlay'

import * as Styled from './styles/ModalRenderer.style'

import localThemes from './themes/ModalRenderer.theme'

import { ModalRendererProps, AdditionalModalChildrenProps } from './ModalRenderer.types'

import Renderer from '../Renderer'

import { RendererState } from '../Renderer.types'

import setInitialFocus from '../utils/setInitialFocus'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'

export class ModalRenderer extends Renderer<ModalRendererProps, RendererState> {
  public static defaultProps = {
    appearance: 'primary',
    size: 1,
    overlayBlur: false,
  }

  get additionalChildrenProps(): AdditionalModalChildrenProps {
    return {
      modalSize: this.props.size!,
    }
  }

  public render(): ReactNode {
    return createPortal(
      <Styled.ModalRenderer zIndex={this.props.zIndex}>
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['scaleUp', 'fadeIn'] : ['none'],
            duration: this.props.animate ? 200 : 0,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? ['fadeOut'] : ['none'],
            duration: this.props.animate ? 200 : 0,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.ModalWrapper>
            <Styled.ContainerWrapper size={this.props.size}>
              <Container
                elevation
                paddingLevel={0}
                appearance={localThemes(this.props.theme!).appearance[this.props.appearance!].containerAppearance}
              >
                <FocusLock shards={this.props.focusEnabledRefs}>
                  <If condition={this.props.isClosable}>
                    <CloseButton
                      srName={this.props.srName}
                      onClick={this.getOnCloseHandler(OverlayCloseSource.MODAL_CROSS_CLICK)}
                      inverse={localThemes(this.props.theme!).appearance[this.props.appearance!].inverseIcon}
                    />
                  </If>
                  <Styled.ModalContent>
                    {React.Children.map(this.props.children, (child) => {
                      if (React.isValidElement(child)) {
                        return React.cloneElement(child, this.additionalChildrenProps)
                      }
                      return child
                    })}
                  </Styled.ModalContent>
                </FocusLock>
              </Container>
            </Styled.ContainerWrapper>
          </Styled.ModalWrapper>
        </Animate>
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['fadeIn'] : ['none'],
            duration: this.props.animate ? 400 : 0,
          }}
          exit={{
            animations: this.props.animate ? ['fadeOut'] : ['none'],
            duration: this.props.animate ? 200 : 0,
          }}
        >
          <Overlay
            show
            onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.MODAL_OUTSIDE_CLICK) : undefined}
            position='fixed'
            zIndex={0}
            blur={this.props.overlayBlur}
          />
        </Animate>
      </Styled.ModalRenderer>,
      document.body,
    )
  }
}

export default withTheme(ModalRenderer)
