import { css, FlattenSimpleInterpolation } from 'styled-components'

import respondTo from './respondTo'

import { MixinResponsiveCalc, properties, operators } from './responsiveCalc.types'

import { spacingKeys } from '../constants/spacing.types'

import { Theme } from '../themes/themes.types'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (
    theme: Theme,
    property: properties,
    initialvalue: string,
    operator: operators,
    spacingValue: spacingKeys,
  ): FlattenSimpleInterpolation => {
    return css`
      ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].sm}px);

      ${respondTo.md(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].md}px);
      `)}

      ${respondTo.lg(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].lg}px);
      `)}

      ${respondTo.xl(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].xl}px);
      `)}
    `
  },
) as MixinResponsiveCalc
