import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSimpleNotificationProps } from './SimpleNotification.style.types'

export const NotificationBody = styled.div((props: StyledSimpleNotificationProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;

  & > * {
    margin-right: ${props.theme.spacing.fixed[3]}px;

    &:last-child {
      margin-right: 0;
    }
  }
`)

export const NotificationIcon = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`)

export const CloseButton = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`)
