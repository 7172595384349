import React, { FC, memo } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import * as Styled from './styles/CloseButton.style'

import { CloseButtonProps } from './CloseButton.types'

const CloseButton: FC<CloseButtonProps> = ({ srName, onClick, inverse }) => {
  return (
    <Styled.CloseButton>
      <InteractiveIcon name='close' srText={`Close ${srName}`} inverse={inverse} onClick={onClick} size={5} isResponsive={false} />
    </Styled.CloseButton>
  )
}

export default memo(CloseButton)
