import { Typography } from '@vfuk/core-styles/dist/constants/typography.types'

import fontWeight from './fontWeight'

const typography: Typography = {
  paragraph: {
    1: {
      fontWeight: fontWeight[2],
      marginBottom: 3,

      fontMobile: '14px',
      lineHeightMobile: '18px',
      letterSpacingMobile: '0',

      fontTablet: '14px',
      lineHeightTablet: '18px',
      letterSpacingTablet: '0',

      fontDesktop: '14px',
      lineHeightDesktop: '18px',
      letterSpacingDesktop: '0',
    },

    2: {
      fontWeight: fontWeight[2],
      marginBottom: 3,

      fontMobile: '16px',
      lineHeightMobile: '20px',
      letterSpacingMobile: '0',

      fontTablet: '18px',
      lineHeightTablet: '26px',
      letterSpacingTablet: '0',

      fontDesktop: '18px',
      lineHeightDesktop: '26px',
      letterSpacingDesktop: '0',
    },
  },

  heading: {
    1: {
      fontWeight: fontWeight[2],
      marginBottom: 3,

      fontMobile: '18px',
      lineHeightMobile: '26px',
      letterSpacingMobile: '0',

      fontTablet: '20px',
      lineHeightTablet: '28px',
      letterSpacingTablet: '0',

      fontDesktop: '20px',
      lineHeightDesktop: '28px',
      letterSpacingDesktop: '0',
    },

    2: {
      fontWeight: fontWeight[1],
      marginBottom: 3,

      fontMobile: '20px',
      lineHeightMobile: '28px',
      letterSpacingMobile: '0',

      fontTablet: '24px',
      lineHeightTablet: '32px',
      letterSpacingTablet: '0',

      fontDesktop: '28px',
      lineHeightDesktop: '32px',
      letterSpacingDesktop: '0',
    },

    3: {
      fontWeight: fontWeight[1],
      marginBottom: 5,

      fontMobile: '24px',
      lineHeightMobile: '32px',
      letterSpacingMobile: '0',

      fontTablet: '32px',
      lineHeightTablet: '40px',
      letterSpacingTablet: '0',

      fontDesktop: '40px',
      lineHeightDesktop: '48px',
      letterSpacingDesktop: '0',
    },

    4: {
      fontWeight: fontWeight[1],
      marginBottom: 5,

      fontMobile: '28px',
      lineHeightMobile: '36px',
      letterSpacingMobile: '0',

      fontTablet: '40px',
      lineHeightTablet: '48px',
      letterSpacingTablet: '0',

      fontDesktop: '56px',
      lineHeightDesktop: '64px',
      letterSpacingDesktop: '0',
    },

    5: {
      fontWeight: fontWeight[1],
      marginBottom: 5,

      fontMobile: '32px',
      lineHeightMobile: '40px',
      letterSpacingMobile: '0',

      fontTablet: '56px',
      lineHeightTablet: '64px',
      letterSpacingTablet: '0',

      fontDesktop: '64px',
      lineHeightDesktop: '72px',
      letterSpacingDesktop: '-0.5px',
    },
  },
}

export default typography
