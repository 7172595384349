import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledUnitInputProps, StyledUnitInputWrapperProps } from './UnitInput.style.types'

import localThemes from '../../../themes/DateTextInput.theme'

export const UnitInput = styled.input((props: StyledUnitInputProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    position: relative;
    border: 0;
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    color: ${props.theme.color.monochrome5.default};
    ${props.theme.mixins.advancedSpacing('padding', [1, 2])};
    ${props.theme.mixins.typography.heading(1, false)}
    text-align: center;

    ${props.unitType !== 'year' && css`
      ${props.theme.mixins.responsiveSizing('width', 10)};
    `}

    ${props.unitType === 'year' && css`
      width: ${localTheme.yearInputWidth.sm};

      ${props.theme.mixins.respondTo.md(css`
        width: ${localTheme.yearInputWidth.mdAndAbove};
      `)}
    `}

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }

    ${props.disabled && css`
      background-color: ${props.theme.color.monochrome2.default};
      color: ${props.theme.color.monochrome5.disabled};
      cursor: not-allowed;
    `}
  `
})

export const UnitInputContainer = styled.div((props: StyledUnitInputWrapperProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;

  ${props.showDelimeter && css`
    &::after {
      ${props.theme.mixins.advancedSpacing('padding', [0, 2])};
      content: '|';
    }
  `}
`)

type LabelProps = Pick<StyledUnitInputProps, 'theme'>
export const Label = styled.label((props: LabelProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)
