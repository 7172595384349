import React, { PureComponent, ReactNode } from 'react'

import { FunctionalTab } from '@vfuk/core-functional-tabs'

import * as Styled from './styles/TabTitle.style'

import { TabTitleProps } from './TabTitle.types'

export class TabTitle extends PureComponent<TabTitleProps> {
  public render(): ReactNode {
    return (
      <Styled.TabTitleWrapper>
        <FunctionalTab tabId={this.props.tabId}>
          <Styled.TabTitle isActiveTab={this.props.isActiveTab} title={this.props.text}>
            <span>{this.props.text}</span>
          </Styled.TabTitle>
        </FunctionalTab>
      </Styled.TabTitleWrapper>
    )
  }
}
