import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import WS10 from './DateTextInput.ws10.theme'
import VOXI from './DateTextInput.voxi.theme'

import { DateTextInputTheme } from './DateTextInput.theme.types'

const getLocalTheme = (theme: Theme): DateTextInputTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI
    case 'WS2':
    case 'WS10':
    default:
      return WS10
  }
}

export default getLocalTheme
