import React from 'react'

import Paragraph from '@vfuk/core-paragraph'
import Heading from '@vfuk/core-heading'

import * as Styled from './styles/AdditionalInfo.style'

import { FooterAdditionalInfo } from '../../Footer.types'

const AdditionalInfo = (props: FooterAdditionalInfo): JSX.Element | null => {
  const { content } = props

  if (!content) return null

  return (
    <>
      <If condition={content.heading}>
        <Heading level={3} size={1} inverse noMargin weight={3}>
          <Styled.AdditionalInfoContent>{content.heading}</Styled.AdditionalInfoContent>
        </Heading>
      </If>
      <If condition={content.text}>
        <Paragraph weight={2}>
          <Styled.AdditionalInfoContent>{content.text}</Styled.AdditionalInfoContent>
        </Paragraph>
      </If>
    </>
  )
}

export default AdditionalInfo
