/* eslint-disable max-len */
import React, { ReactNode, PureComponent } from 'react'

import Heading from '@vfuk/core-heading'
import { CollapsibleContainer, CollapsibleContainerHeader, CollapsibleContainerBody } from '@vfuk/core-collapsible-container'

import * as Styled from './styles/CollapsibleLinkListGroup.style'

import { CollapsibleLinkListGroupProps, CollapsibleLinkListGroupState } from './CollapsibleLinkListGroup.types'

import LinkList from '../LinkList'

export default class CollapsibleLinkListGroup extends PureComponent<CollapsibleLinkListGroupProps> {
  public state: CollapsibleLinkListGroupState = {
    isActive: false,
  }

  private setActiveState = (): void => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  public render(): ReactNode {
    return (
      <Styled.CollapsibleLinkListGroup>
        {this.props.linkGroups.map((linkGroup, key) => {
          return (
            <Styled.CollapsibleLinkList key={key} isActive={this.state.isActive}>
              <CollapsibleContainer inverse onToggle={this.setActiveState}>
                <CollapsibleContainerHeader>
                  <Styled.HeadingContainer isActive={this.state.isActive}>
                    <Heading text={linkGroup.headingText} size={2} />
                  </Styled.HeadingContainer>
                </CollapsibleContainerHeader>
                <CollapsibleContainerBody>
                  <Styled.LinkListContainer>
                    <LinkList links={linkGroup.links} onInteractionClick={this.props.onInteractionClick} />
                  </Styled.LinkListContainer>
                </CollapsibleContainerBody>
              </CollapsibleContainer>
            </Styled.CollapsibleLinkList>
          )
        })}
      </Styled.CollapsibleLinkListGroup>
    )
  }
}
