import React, { ReactElement } from 'react'

import * as Styled from './styles/LegalLinks.style'

import { LegalLinksProps } from './LegalLinks.types'

const LegalLinks: React.FC<LegalLinksProps> = ({ links, onInteractionClick }: LegalLinksProps): ReactElement => {
  return (
    <Styled.LegalLinks>
      {links.map((link, index) => {
        return (
          <Styled.LegalLink key={index} url={link.url} htmlAttributes={link.htmlAttributes} onClick={onInteractionClick}>
            {link.text}
          </Styled.LegalLink>
        )
      })}
    </Styled.LegalLinks>
  )
}

export default LegalLinks
