import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledFlyoutRendererProps } from './FlyoutRenderer.style.types'

import localThemes from '../themes/FlyoutRenderer.theme'

type FlyoutRendererProps = Pick<StyledFlyoutRendererProps, 'zIndex'>
export const FlyoutRenderer = styled.div((props: FlyoutRendererProps): FlattenSimpleInterpolation => css`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props.zIndex};
`)

export const FlyoutRendererContent = styled.div((props: StyledFlyoutRendererProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    position: fixed;
    ${props.side}: 0;
    top: 0;
    bottom: 0;
    z-index: ${props.zIndex + 1};
    width: calc(100% - ${props.theme.spacing.responsive[3].sm}px);
    background: ${localTheme.appearance[props.appearance!].backgroundColor};
    color: ${localTheme.appearance[props.appearance!].textColor};
    ${props.theme.mixins.advancedSpacing('padding', [10, 5, 5])}

    ${props.theme.mixins.respondTo.md(css`
      width: 60%;
      max-width: 560px;
    `)}
  `
})
