import React, { PureComponent, ReactNode, ReactElement } from 'react'

import { FunctionalTabs } from '@vfuk/core-functional-tabs'

import * as Styled from './styles/NavigationalTabs.style'

import { NavigationalTabsProps } from './NavigationalTabs.types'

import { TabTitle } from './components/TabTitle/TabTitle'

export class NavigationalTabs extends PureComponent<NavigationalTabsProps> {
  public static defaultProps: Partial<NavigationalTabsProps> = {
    width: 'auto',
  }

  private tabsListRef = React.createRef<HTMLDivElement>()

  public render(): ReactNode {
    return (
      <FunctionalTabs
        onTabClick={this.props.onTabClick}
        externallySetTabId={this.props.externallySetTabId}
        accessibilityInfiniteScroll={this.props.accessibilityInfiniteScroll}
      >
        <Styled.TabList width={this.props.width!} ref={this.tabsListRef}>
          {/* Maps through <Tab> children to pull out props and pass to <FunctionalTab> */}
          {React.Children.map(this.props.children, (child: ReactElement, key: number): ReactNode => {
            return <TabTitle key={key} tabId={child.props.id} isActiveTab={child.props.isActiveTab} text={child.props.text} />
          })}
        </Styled.TabList>
        {/* <Tab> children render <FunctionalTabPanel> components which contain the tab content */}
        {this.props.children}
      </FunctionalTabs>
    )
  }
}
