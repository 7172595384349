import React, { PureComponent, ReactNode } from 'react'

import isFunction from 'lodash/isFunction'

import MatchMedia from '@vfuk/core-match-media'
import Paragraph from '@vfuk/core-paragraph'
import LinkWithIcon from '@vfuk/core-link-with-icon'

import { InteractionEvent } from '@vfuk/core-interaction/dist/Interaction.types'

import * as Styled from './styles/Footer.style'

import Social from './components/Social/Social'
import LegalLinks from './components/LegalLinks/LegalLinks'
import CollapsibleLinkListGroup from './components/CollapsibleLinkListGroup'
import LinkListGroup from './components/LinkListGroup'
import AdditionalInfo from './components/AdditionalInfo'

import { FooterProps } from './Footer.types'

export default class Footer extends PureComponent<FooterProps> {
  public static defaultProps: Partial<FooterProps> = {
    social: [],
    linkGroups: [],
    legalLinks: [],
    copyrightText: '',
  }

  public componentDidMount(): void {
    if (isFunction(this.props.onLoad)) {
      this.props.onLoad()
    }
  }

  public onInteractionClick = (event: InteractionEvent): void => {
    if (isFunction(this.props.onInteractionClick)) {
      this.props.onInteractionClick(event)
    }
  }

  public render(): ReactNode {
    return (
      <Styled.Footer>
        <Styled.FooterContainer>
          <Styled.SocialAndCommunity>
            <Social socialButtons={this.props.social} onInteractionClick={this.onInteractionClick} />
            <If condition={this.props.communityLink}>
              <LinkWithIcon
                icon={{
                  name: 'community-or-foundation',
                  group: 'ukLegacy',
                }}
                inverse
                text={this.props.communityLink!.text!}
                htmlAttributes={this.props.communityLink!.htmlAttributes}
                size={3}
                href={this.props.communityLink!.url}
                onClick={this.props.onInteractionClick}
              />
            </If>
          </Styled.SocialAndCommunity>
          <MatchMedia breakpoint='md' andBelow>
            <CollapsibleLinkListGroup linkGroups={this.props.linkGroups} onInteractionClick={this.onInteractionClick} />
          </MatchMedia>
          <MatchMedia breakpoint='lg' andAbove>
            <LinkListGroup linkGroups={this.props.linkGroups} onInteractionClick={this.onInteractionClick} />
          </MatchMedia>
          <LegalLinks links={this.props.legalLinks} onInteractionClick={this.onInteractionClick} />
          <If condition={this.props.additionalInfo}>
            <AdditionalInfo {...this.props.additionalInfo} />
          </If>
          <Styled.Copyright>
            <Paragraph>{`${this.props.copyrightText} ${this.props.companyInfo}`}</Paragraph>
          </Styled.Copyright>
        </Styled.FooterContainer>
      </Styled.Footer>
    )
  }
}
