import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { SpanTheme } from './Span.theme.types'

const spanLocal = (theme: Theme): SpanTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome8,
        inverse: {
          color: theme.color.primary2,
        },
      },
      secondary: {
        color: theme.color.primary1,
        inverse: {
          color: theme.color.secondary7,
        },
      },
      alt1: {
        color: theme.color.info,
        inverse: {
          color: theme.color.secondary4,
        },
      },
      alt2: {
        color: theme.color.secondary1,
        inverse: {
          color: theme.color.secondary5,
        },
      },
      alt3: {
        color: theme.color.monochrome7,
        inverse: {
          color: theme.color.primary2,
        },
      },
      alt4: {
        color: theme.color.monochrome6,
        inverse: {
          color: theme.color.primary2,
        },
      },
    },
  }
}

export default spanLocal
