import React, { ReactElement } from 'react'

import ListItem from '@vfuk/core-list-item'

import * as Styled from './styles/LinkList.style'

import { LinkListProps } from './LinkList.types'

const LinkList: React.FC<LinkListProps> = ({ links, onInteractionClick }: LinkListProps): ReactElement => {
  return (
    <Styled.LinkList>
      {links.map((link, key) => {
        return (
          <ListItem key={key} url={link.url} htmlAttributes={link?.htmlAttributes} onClick={onInteractionClick}>
            {link.text}
          </ListItem>
        )
      })}
    </Styled.LinkList>
  )
}

export default LinkList
