import { SimpleNotificationTheme } from './SimpleNotification.theme.types'

const simpleNotificationLocal = (): SimpleNotificationTheme => ({
  appearance: {
    primary: {
      containerAppearance: 'primary',
      iconAppearance: 'primary',
      linkAppearance: 'primary',
      inverseText: false,
      inverseIcon: false,
    },
    secondary: {
      containerAppearance: 'secondary',
      iconAppearance: 'primary',
      linkAppearance: 'primary',
      inverseText: false,
      inverseIcon: false,
    },
    alt1: {
      containerAppearance: 'alt2',
      iconAppearance: 'primary',
      linkAppearance: 'primary',
      inverseText: true,
      inverseIcon: true,
    },
    alt2: {
      containerAppearance: 'alt3',
      iconAppearance: 'primary',
      linkAppearance: 'primary',
      inverseText: true,
      inverseIcon: true,
    },
  },
})

export default simpleNotificationLocal
