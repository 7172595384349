import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledCloseButtonProps } from './CloseButton.style.types'

export const CloseButton = styled.div((props: StyledCloseButtonProps): FlattenSimpleInterpolation => css`
  position: absolute;
  top: ${props.theme.spacing.responsive[4].sm}px;
  right: ${props.theme.spacing.responsive[4].sm}px;
  z-index: 3;

  ${props.theme.mixins.respondTo.md(css`
    top: ${props.theme.spacing.responsive[4].md}px;
    right: ${props.theme.spacing.responsive[4].md}px;
  `)}
`)
