import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledFunctionalTabPanelProps } from './FunctionalTabPanel.style.types'

export const TabPanel = styled.div(
  (props: StyledFunctionalTabPanelProps): FlattenSimpleInterpolation => css`
    ${!props.isActiveTab && `
      position: absolute;
      opacity: 0;
      height: 0;
      overflow: hidden;
    `}
  `,
)
