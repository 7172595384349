/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import localThemes from '@vfuk/core-button/dist/themes/Button.theme'

import { StyledButtonWithIconProps } from './ButtonWithIcon.style.types'

export const ButtonWithIcon = styled(Interaction)((props: StyledButtonWithIconProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: .4s all ease;
    line-height: 12px;
    width: 100%;
    max-height: 44px;

    font-weight: ${props.theme.fontWeight[2]};
    color: ${localTheme.appearance[props.appearance!].color};
    background: ${localTheme.appearance[props.appearance!].backgroundColor};
    border-radius: ${localTheme.borderRadius};
    padding: ${props.theme.spacing.fixed[3]}px ${props.theme.spacing.fixed[10]}px;

    [class*='Icon'] {
      transition: .4s all ease;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: ${localTheme.borderRadius};
    }

    ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].border })}

    ${props.theme.mixins.respondTo.md(css`
       width: ${localTheme.minWidth};
       max-height: 48px;
       line-height: 16px;
    `)}

     ${props.width === 'auto' && css`
       width: auto;

       ${props.theme.mixins.respondTo.md(css`
         width: auto;
       `)}
     `}

     ${props.width === 'full' && css`
       width: 100%;

       ${props.theme.mixins.respondTo.md(css`
         width: 100%;
       `)}
     `}

    ${props.state && css`
      opacity: ${localTheme.appearance[props.appearance!].state[props.state].opacity};
      color: ${localTheme.appearance[props.appearance!].state[props.state].color};
      background: ${localTheme.appearance[props.appearance!].state[props.state].backgroundColor};

      ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].state[props.state].border })}
    `}

    ${props.state === 'disabled' && css`
      cursor: default;

      [class*='Icon'] {
        svg {
          ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].state[props.state].color)};
        }
      }

      [class*='LoadingSpinner'] {
        svg {
          & > * {
            stroke: ${localTheme.appearance[props.appearance!].state[props.state].color};
          }
        }
      }
    `}

    ${!props.loading && css`
      [class*='Icon'] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      ${props.icon.justify !== 'right' && css`
        [class*='Icon'] {
          left: ${props.theme.spacing.fixed[3]}px;
        }
      `}

      ${props.icon.justify === 'right' && css`
        [class*='Icon'] {
          right: ${props.theme.spacing.fixed[3]}px;
        }
      `}
    `}

    &:hover {
      color: ${localTheme.appearance[props.appearance!].hover.color};
      background: ${localTheme.appearance[props.appearance!].hover.backgroundColor};

      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].hover.color)};
        }
      }

      [class*='LoadingSpinner'] {
        svg {
          & > * {
            stroke: ${localTheme.appearance[props.appearance!].hover.color};
          }
        }
      }

      ${props.state && css`
        color: ${localTheme.appearance[props.appearance!].state[props.state].hover.color};
        background: ${localTheme.appearance[props.appearance!].state[props.state].hover.backgroundColor};

        [class*='Icon'] {
          * {
            ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].state[props.state].hover.color)};
          }
        }

        [class*='LoadingSpinner'] {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance!].state[props.state].hover.color};
            }
          }
        }
      `}
    }

    ${props.inverse && css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
      background: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};

      ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].inverse.border })}

      ${props.state && css`
        opacity: ${localTheme.appearance[props.appearance!].inverse.state[props.state].opacity};
        color: ${localTheme.appearance[props.appearance!].inverse.state[props.state].color};
        background: ${localTheme.appearance[props.appearance!].inverse.state[props.state].backgroundColor};

        ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].inverse.state[props.state].border })}
      `}

      &:hover {
        color: ${localTheme.appearance[props.appearance!].inverse.hover.color};
        background: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};

        ${props.state && css`
          color: ${localTheme.appearance[props.appearance!].state[props.state].hover.color};
          background: ${localTheme.appearance[props.appearance!].state[props.state].hover.backgroundColor};
        `}

        [class*='Icon'] {
          * {
            ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.hover.color)};
          }
        }

        [class*='LoadingSpinner'] {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance!].inverse.hover.color};
            }
          }
        }
      }
    `}
  `
})

export const HiddenText = styled.span((): FlattenSimpleInterpolation => {
  return css`
    visibility: hidden;
  `
})

export const LoadingIcon = styled.div((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})
/* eslint-enable complexity, sonarjs/cognitive-complexity */
