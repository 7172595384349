import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledErrorStatusModalProps } from './ErrorStatusModal.style.types'

export const ContentWrapper = styled.div((props: StyledErrorStatusModalProps): FlattenSimpleInterpolation => {
  const localIconTheme = props.theme.getIconProperties('system')
  return css`
    width: 100%;
    [class*=Heading] {
      padding-left: calc(${localIconTheme.size[5].sm}px + ${props.theme.spacing.responsive[3].sm}px);
    }

    ${props.theme.mixins.respondTo.md(css`
      margin-bottom: 0;
      flex-grow: 1;
      padding-left: ${props.theme.spacing.fixed[5]}px;

      [class*=Heading] {
        padding-left: 0;
        padding-right: 0;
      }
    `)}
  `
})

export const ModalBody = styled.div((props: StyledErrorStatusModalProps): FlattenSimpleInterpolation => css`
  position: relative;
  display: flex;
  flex-direction: column;

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
  `)}
`)

export const IconContainer = styled.div((props: StyledErrorStatusModalProps): FlattenSimpleInterpolation => css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  ${props.theme.mixins.respondTo.md(css`
    padding-top: ${props.theme.spacing.fixed[2]}px;
    position: static;
    flex-shrink: 0;
  `)}
`)
