import React, { PureComponent, ReactNode } from 'react'

import { FunctionalTabPanel } from '@vfuk/core-functional-tabs'

import { TabProps } from './Tab.types'

export class Tab extends PureComponent<TabProps> {
  public static defaultProps: Partial<TabProps> = {
    isLazy: false,
  }

  public render(): ReactNode {
    return (
      <FunctionalTabPanel tabId={this.props.id} isLazy={this.props.isLazy}>
        {this.props.children}
      </FunctionalTabPanel>
    )
  }
}
