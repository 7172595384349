import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledLinkListGroupProps } from './LinkListGroup.style.types'

export const LinkListGroupContainer = styled.div((props: StyledLinkListGroupProps): FlattenSimpleInterpolation => css`
  display: flex;
  margin-bottom: ${props.theme.spacing.fixed[7]}px;
  justify-content: space-between;
`)

export const LinkGroup = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`)
