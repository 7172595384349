import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledAnimateProps } from './Animate.style.types'

import { animations } from '../animations/animations'
import getDuration from '../utils/getDuration'
import getDelay from '../utils/getDelay'
import convertToSeconds from '../utils/convertToSeconds'

const getAnimationMixins = (animationCfg: StyledAnimateProps['enter'] | StyledAnimateProps['exit']): FlattenSimpleInterpolation => {
  // Get the keyframes for the animations being run
  const animationKeyFrames = animationCfg.animations.map((animationName) => {
    if (animationName === 'none') return
    return animations[animationName].animation()
  })

  // Gets the animation CSS
  const animationCss = animationCfg.animations
    .map((animationName) => {
      if (animationName === 'none') return
      const animationDuration = getDuration(animationCfg, animationName)
      return `${animationName} ${convertToSeconds(animationDuration)}s ${convertToSeconds(getDelay(animationCfg))}s`
    })
    .join(', ')

  return css`
    ${animationKeyFrames}
    animation: ${animationCss};
  `
}

export const Animate = styled.span((props: StyledAnimateProps): FlattenSimpleInterpolation => {
  return css`
    .animation-container {
      &-enter {
        ${getAnimationMixins(props.enter)}
        animation-fill-mode: both;
      }

      &-exit {
        ${getAnimationMixins(props.exit)}
        animation-fill-mode: both;
      }
    }
  `
})
