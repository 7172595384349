import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledNavigationalTabsProps } from './NavigationalTabs.style.types'

export const TabList = styled.div((props: StyledNavigationalTabsProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: stretch;
  justify-content: center;

  ${props.width === 'full' && css`
    justify-content: space-around;
  `}
`)
