import React, { ReactElement } from 'react'

import Heading from '@vfuk/core-heading'

import * as Styled from './styles/LinkListGroup.style'

import { LinkListGroupProps } from './LinkListGroup.types'

import LinkList from '../LinkList'

const LinkListGroup: React.FC<LinkListGroupProps> = ({ linkGroups, onInteractionClick }: LinkListGroupProps): ReactElement => {
  return (
    <Styled.LinkListGroupContainer>
      {linkGroups.map((linkGroup, key) => {
        return (
          <Styled.LinkGroup key={key}>
            <Heading text={linkGroup.headingText} size={2} inverse />
            <LinkList links={linkGroup.links} onInteractionClick={onInteractionClick} />
          </Styled.LinkGroup>
        )
      })}
    </Styled.LinkListGroupContainer>
  )
}

export default LinkListGroup
