import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledFunctionalTabProps } from './FunctionalTab.style.types'

export const ClickableTab = styled.button`
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;

  & > * {
    cursor: pointer;
  }
`

export const InvisibleSpan = styled.span((props: StyledFunctionalTabProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)
