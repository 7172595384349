import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { FunctionalTabListProps } from '../FunctionalTabList.types'

export const StyledTabList = styled.div((props: FunctionalTabListProps): FlattenSimpleInterpolation => css`
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: ${props.justifyContent || 'space-between'};
    ${props.maxWidth && `max-width: ${props.maxWidth}px`};
  `)
