import React, { FC, ReactElement } from 'react'
import { withTheme } from 'styled-components'

import Container from '@vfuk/core-container'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import Link from '@vfuk/core-link'
import InteractiveIcon from '@vfuk/core-interactive-icon'

import * as Styled from './styles/SimpleNotification.style'

import { SimpleNotificationProps } from './SimpleNotification.types'

import localTheme from './themes/SimpleNotification.theme'

export const SimpleNotification: FC<SimpleNotificationProps> = ({
  appearance = 'primary',
  icon,
  link,
  text,
  closeButton,
  theme,
}: SimpleNotificationProps): ReactElement => {
  const simpleNotificationAppearance = localTheme(theme).appearance[appearance!]
  return (
    <Container appearance={simpleNotificationAppearance.containerAppearance} elevation={true}>
      <Styled.NotificationBody>
        <If condition={icon}>
          <Styled.NotificationIcon>
            <Icon
              name={icon!.name}
              group={icon!.group}
              size={4}
              appearance={simpleNotificationAppearance.iconAppearance}
              inverse={simpleNotificationAppearance.inverseIcon}
            />
          </Styled.NotificationIcon>
        </If>
        <Paragraph noMargin inverse={simpleNotificationAppearance.inverseText}>
          <Choose>
            <When condition={link}>
              {`${text} `}
              <Link
                {...link}
                appearance={simpleNotificationAppearance.linkAppearance}
                inverse={simpleNotificationAppearance.inverseText}
                showUnderline
              />
            </When>
            <Otherwise>{text}</Otherwise>
          </Choose>
        </Paragraph>
        <If condition={closeButton}>
          <Styled.CloseButton>
            <InteractiveIcon
              srText={closeButton!.srText}
              name='close'
              appearance={simpleNotificationAppearance.iconAppearance}
              inverse={simpleNotificationAppearance.inverseIcon}
              onClick={closeButton!.onClick}
            />
          </Styled.CloseButton>
        </If>
      </Styled.NotificationBody>
    </Container>
  )
}

export default withTheme(SimpleNotification)
