import React, { ReactElement, Fragment } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import * as Styled from './styles/Social.style'

import { SocialProps } from './Social.types'

const Social: React.FC<SocialProps> = ({ socialButtons, onInteractionClick }: SocialProps): ReactElement => {
  return (
    <Styled.SocialContainer>
      {socialButtons.map((socialButton, key) => {
        return (
          <Fragment key={key}>
            <InteractiveIcon
              name={socialButton.name}
              href={socialButton.url}
              group='social'
              srText={socialButton.srText}
              htmlAttributes={socialButton.htmlAttributes}
              onClick={onInteractionClick}
              size={5}
              inverse
            />
          </Fragment>
        )
      })}
    </Styled.SocialContainer>
  )
}

export default Social
