import React, { PureComponent, ReactNode } from 'react'
import get from 'lodash/get'

import { withTheme } from 'styled-components'

import { ImageProps, Breakpoint, SrcSetSizes, ImageSrc } from './Image.types'

export class Image extends PureComponent<ImageProps> {
  private static breakpoints: Breakpoint[] = ['xl', 'lg', 'md', 'sm']

  private getSourceSetValue(src: string | undefined, size: SrcSetSizes): string {
    if (!src) return ''
    const resolutionMap = {
      x1: '',
      x2: '2x',
      x3: '3x',
    }
    return `${src} ${resolutionMap[size]}`.trim()
  }

  private getSource(breakpoint: Breakpoint): string | null {
    const currentBreakpoint = Image.breakpoints.indexOf(breakpoint)

    const sourceSet = get(this.props[breakpoint], 'srcSet')
    const src = get(this.props[breakpoint], 'src')
    const nextBreakpoint = Image.breakpoints[currentBreakpoint - 1]

    // If neither then go the next breakpoint
    if (!sourceSet && !src && nextBreakpoint) {
      return this.getSource(nextBreakpoint)
    }

    // If the breakpoint has no sourceSet but has src
    if (!sourceSet && src) return src

    if (sourceSet) {
      return Object.keys(sourceSet)
        .map((size: SrcSetSizes) => this.getSourceSetValue(sourceSet[size], size))
        .filter(Boolean)
        .join(', ')
    }

    return null
  }

  private getFallbackImage(breakpoint: Breakpoint = 'xl'): ImageSrc | undefined {
    const currentBreakpoint = Image.breakpoints.indexOf(breakpoint)
    const imageObj = get(this.props, `${breakpoint}`)
    const nextBreakpoint = Image.breakpoints[currentBreakpoint + 1]

    if (imageObj) return imageObj

    // If no image object found for current breakpoint try next size up
    if (nextBreakpoint) {
      return this.getFallbackImage(nextBreakpoint)
    }
  }

  public render(): ReactNode {
    const fallbackImageObj = this.getFallbackImage()
    return (
      <picture>
        {Image.breakpoints.map(
          (breakpoint: Breakpoint, index: number): ReactNode => {
            const srcSet = this.getSource(breakpoint)
            if (!srcSet) return

            return <source media={`(min-width: ${this.props.theme!.breakpoints[breakpoint]}px)`} srcSet={srcSet} key={index} />
          },
        )}

        {/* fallbackImageObj can be an object OR undefined (based on the function that grabs it) */}
        <If condition={fallbackImageObj}>
          <img
            title={this.props.title}
            alt={this.props.alt}
            src={fallbackImageObj!.src}
            width={fallbackImageObj!.width || '100%'}
            height={fallbackImageObj!.height || 'auto'}
          />
        </If>
      </picture>
    )
  }
}

export default withTheme(Image)
