import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledDateTextInputProps } from './DateTextInput.style.types'

type ThemeOnlyProp = Pick<StyledDateTextInputProps, 'theme'>

export const DateTextInputWrapper = styled.fieldset((): FlattenSimpleInterpolation => css`
  position: relative;
`)

export const Legend = styled.legend((props: ThemeOnlyProp): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.srOnly()}
`)

export const DateTextInput = styled.div((props: StyledDateTextInputProps): FlattenSimpleInterpolation => css`
  color: ${props.theme.color.monochrome5.default};
  transition: .4s box-shadow ease;
  border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome3.default};
  border-radius: ${props.theme.border.radius[2]};
  background-color: ${props.theme.color.monochrome1.default};
  ${props.theme.mixins.advancedSpacing('padding', [1, 3])};
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  ${props.theme.mixins.typography.paragraph(2, false)}

  ${props.state && props.state !== 'disabled' && css`
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color[props.state].default};
    ${props.theme.mixins.spacing('padding-right', 7)};
  `}

  ${props.state === 'disabled' && css`
    background-color: ${props.theme.color.monochrome2.default};
    color: ${props.theme.color.monochrome5.disabled};
    cursor: not-allowed;
  `}
`)
