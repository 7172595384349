import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledCalendarPickerProps } from './CalendarPicker.style.types'

import ChevronLeft from '../assets/chevron-left.svg'
import ChevronRight from '../assets/chevron-right.svg'

export const CalendarPicker = styled.div((props: StyledCalendarPickerProps): FlattenSimpleInterpolation => css`
  position: relative;
  z-index: 10;

  .react-datepicker-wrapper {
    width: 100%;

    ${props.theme.mixins.respondTo.md(css`
      width: 290px;
    `)}
  }

  .react-datepicker {
    right: 0;

    ${props.theme.mixins.respondTo.md(css`
      position: relative;
      left: 0 !important;
    `)}

    ${props.theme.mixins.elevation(true)};
    font-family: ${props.theme.baseFont.fontFamily};
  }

  .react-datepicker__day {
    border-radius: 50%;
    ${props.theme.mixins.typography.paragraph(2, false)};
    ${props.theme.mixins.spacing('margin', 1)};
    ${props.theme.mixins.responsiveSizing('width', 6)};
    ${props.theme.mixins.responsiveSizing('height', 6)};
    ${props.theme.mixins.responsiveSizing('line-height', 6)};
    color: ${props.theme.color.monochrome6.default};

    &:hover {
      background-color: ${props.theme.color.secondary7.default};
      color: ${props.theme.color.monochrome1.default};
    }

    &--selected {
      background-color: ${props.theme.color.success.default};
      color: ${props.theme.color.monochrome6.default};

      &:focus {
        box-shadow: 0 0 0 1px ${props.theme.color.monochrome1.default}, 0 0 0 4px ${props.theme.color.focus.default};
        outline: 3px solid transparent;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${props.theme.color.focus.default};

    &:focus {
      box-shadow: 0 0 0 1px ${props.theme.color.monochrome1.default}, 0 0 0 4px ${props.theme.color.focus.default};
      outline: 3px solid transparent;
    }
  }

  .react-datepicker__header {
    background-color: ${props.theme.color.monochrome1.default};
    border-bottom: none;
  }

  .react-datepicker__day-name {
    ${props.theme.mixins.typography.paragraph(2, false)};
    ${props.theme.mixins.advancedSpacing('margin', [1, 1, 0])};
    ${props.theme.mixins.responsiveSizing('width', 6)};
    font-weight: ${props.theme.fontWeight[3]};
    color: ${props.theme.color.monochrome6.default};
  }

  .react-datepicker-year-header,
  .react-datepicker__current-month {
    ${props.theme.mixins.typography.heading(1, false)};
    ${props.theme.mixins.spacing('margin', 2)};
  }

  .react-datepicker-year-header {
    padding-top: 0;
  }

  .react-datepicker__triangle {
    left: 90% !important;
    border-bottom-color: ${props.theme.color.monochrome1.default} !important;

    ${props.theme.mixins.respondTo.md(css`
      left: 84% !important;
    `)}
  }

  .react-datepicker__month-text {
    ${props.theme.mixins.typography.paragraph(2, false)};
    border-radius: ${props.theme.border.radius[3]};
    margin: ${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[3]}px;
    ${props.theme.mixins.advancedSpacing('margin', [2, 3])};

    &:hover {
      background-color: ${props.theme.color.secondary7.default};
      color: ${props.theme.color.monochrome1.default};
    }
  }

  .react-datepicker__month--selected {
    background-color: ${props.theme.color.success.default};
  }

  .react-datepicker__navigation {
    ${props.theme.mixins.advancedSpacing('margin', [3, 2])};
    border: none;
    ${props.theme.mixins.responsiveSizing('width', 4)};
    ${props.theme.mixins.responsiveSizing('height', 4)};
    background: no-repeat center center transparent;
    background-size: contain;

    &--previous {
      background-image: url(${ChevronLeft});
    }

    &--next {
      background-image: url(${ChevronRight});
    }

    &:focus {
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      outline: 3px solid transparent;
    }
  }
`)
