import { createGlobalStyle, css, FlattenSimpleInterpolation } from 'styled-components'

import { GlobalStylesProps } from './globals.types'
import fontFace from './partials/fontFace'
import normalize from './partials/normalize'
import fontSizes from './partials/fontSizes'
import formElementSuggestions from './partials/formElementSuggestions'

// stylelint-disable property-no-vendor-prefix

const globals = createGlobalStyle(
  (props: GlobalStylesProps): FlattenSimpleInterpolation => css`
    ${normalize}

    html {
      font-family: sans-serif;
      box-sizing: border-box;
      /* stylelint-disable-line */
      -ms-text-size-adjust: 100%;
      -ms-overflow-style: scrollbar;
      /* -webkit-tap-highlight-color: rgba($color-black, 0); */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    body {
      font-size: ${props.theme.typography.paragraph[2].fontMobile};
      line-height: ${props.theme.typography.paragraph[2].lineHeightMobile};
      font-family: ${props.theme.baseFont.fontFamily};
      font-weight: ${props.theme.baseFont.fontWeight};
      color: ${props.theme.baseFont.color};
      background-color: ${props.theme.baseFont.backgroundColor};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: left;

      ${props.theme.mixins.respondTo.md(css`
        font-size: ${props.theme.typography.paragraph[2].fontTablet};
        line-height: ${props.theme.typography.paragraph[2].lineHeightTablet};
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        font-size: ${props.theme.typography.paragraph[2].fontDesktop};
        line-height: ${props.theme.typography.paragraph[2].lineHeightDesktop};
      `)}
    }

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: ${props.theme.color.monochrome4.default};
      font-weight: 400;
    }

    ${fontFace}
    ${fontSizes}
  ${formElementSuggestions}
  `,
)

export default globals
