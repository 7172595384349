import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'

import * as Styled from './styles/Button.style'

import { ButtonProps } from './Button.types'

import localThemes from './themes/Button.theme'

export class Button extends PureComponent<ButtonProps> {
  public static defaultProps: Partial<ButtonProps> = {
    appearance: 'primary',
    width: 'default',
    loading: false,
    htmlAttributes: {},
    inverse: false,
  }

  public render(): ReactNode {
    const localTheme = localThemes(this.props.theme!)
    const isDisabledOrLoading = this.props.state === 'disabled' || this.props.loading!

    return (
      <Styled.Button
        appearance={this.props.appearance!}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        disabled={isDisabledOrLoading}
        href={!isDisabledOrLoading ? this.props.href : undefined}
        id={this.props.id}
        inverse={this.props.inverse!}
        label={this.props.text}
        loading={this.props.loading!}
        onInteraction={this.props.onClick}
        state={this.props.state!}
        target={this.props.htmlAttributes!.target}
        to={!isDisabledOrLoading ? this.props.to : undefined}
        type={this.props.htmlAttributes!.type}
        width={this.props.width!}
      >
        <Choose>
          <When condition={this.props.loading}>
            <Styled.LoadingIcon>
              <Choose>
                <When condition={this.props.inverse}>
                  <LoadingSpinner
                    appearance={localTheme.appearance[this.props.appearance!].inverse.iconAppearance}
                    inverse={localTheme.appearance[this.props.appearance!].inverse.inverseIcon}
                    size={2}
                  />
                </When>
                <Otherwise>
                  <LoadingSpinner
                    appearance={localTheme.appearance[this.props.appearance!].iconAppearance}
                    inverse={localTheme.appearance[this.props.appearance!].inverseIcon}
                    size={2}
                  />
                </Otherwise>
              </Choose>
            </Styled.LoadingIcon>
            <Styled.HiddenText>{this.props.text}</Styled.HiddenText>
          </When>
          <When condition={this.props.inverse && this.props.state === 'selected'}>
            <Icon
              appearance={localTheme.appearance[this.props.appearance!].inverse.state[this.props.state!].iconAppearance}
              inverse={localTheme.appearance[this.props.appearance!].inverse.state[this.props.state!].inverseIcon}
              name='tick'
              group='system'
              size={3}
              isResponsive={false}
            />
            {this.props.text}
          </When>
          <When condition={this.props.state === 'selected'}>
            <Icon
              appearance={localTheme.appearance[this.props.appearance!].state[this.props.state!].iconAppearance}
              inverse={localTheme.appearance[this.props.appearance!].state[this.props.state!].inverseIcon}
              name='tick'
              group='system'
              size={3}
              isResponsive={false}
            />
            {this.props.text}
          </When>
          <Otherwise>{this.props.text}</Otherwise>
        </Choose>
      </Styled.Button>
    )
  }
}

export default withTheme(Button)
