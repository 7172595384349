import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'
import localThemes from '@vfuk/core-button/dist/themes/Button.theme'

import * as Styled from './styles/ButtonWithIcon.style'

import { ButtonWithIconProps } from './ButtonWithIcon.types'

export class ButtonWithIcon extends PureComponent<ButtonWithIconProps> {
  public static defaultProps: Partial<ButtonWithIconProps> = {
    appearance: 'primary',
    width: 'default',
    loading: false,
    htmlAttributes: {},
    inverse: false,
  }

  public render(): ReactNode {
    const appearance = localThemes(this.props.theme!).appearance[this.props.appearance!]

    return (
      <Styled.ButtonWithIcon
        appearance={this.props.appearance!}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        disabled={this.props.state === 'disabled' || this.props.loading!}
        href={this.props.href}
        icon={this.props.icon!}
        id={this.props.id}
        inverse={this.props.inverse!}
        label={this.props.text}
        loading={this.props.loading!}
        onInteraction={this.props.onClick}
        state={this.props.state!}
        target={this.props.htmlAttributes!.target}
        to={this.props.to}
        type={this.props.htmlAttributes!.type}
        width={this.props.width!}
      >
        <Choose>
          <When condition={this.props.inverse}>
            <Choose>
              <When condition={this.props.state === 'selected'}>
                <Icon
                  appearance={appearance.inverse.state[this.props.state!].iconAppearance}
                  inverse={appearance.inverse.state[this.props.state!].inverseIcon}
                  name='tick'
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </When>
              <When condition={this.props.state === 'success'}>
                <Icon
                  appearance={appearance.inverse.state[this.props.state!].iconAppearance}
                  inverse={appearance.inverse.state[this.props.state!].inverseIcon}
                  name={this.props.icon.name}
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </When>
              <When condition={this.props.loading}>
                <Styled.LoadingIcon>
                  <LoadingSpinner appearance={appearance.inverse.iconAppearance} inverse={appearance.inverse.inverseIcon} size={2} />
                </Styled.LoadingIcon>
                <Styled.HiddenText>{this.props.text}</Styled.HiddenText>
              </When>
              <Otherwise>
                <Icon
                  appearance={appearance.inverse.iconAppearance}
                  inverse={appearance.inverse.inverseIcon}
                  name={this.props.icon.name}
                  group='system'
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </Otherwise>
            </Choose>
          </When>
          <Otherwise>
            <Choose>
              <When condition={this.props.state === 'selected'}>
                <Icon
                  appearance={appearance.state[this.props.state!].iconAppearance}
                  inverse={appearance.state[this.props.state!].inverseIcon}
                  name='tick'
                  group='system'
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </When>
              <When condition={this.props.state === 'success'}>
                <Icon
                  appearance={appearance.state[this.props.state!].iconAppearance}
                  inverse={appearance.state[this.props.state!].inverseIcon}
                  name={this.props.icon.name}
                  group='system'
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </When>
              <When condition={this.props.loading}>
                <Styled.LoadingIcon>
                  <LoadingSpinner appearance={appearance.iconAppearance} inverse={appearance.inverseIcon} size={2} />
                </Styled.LoadingIcon>
                <Styled.HiddenText>{this.props.text}</Styled.HiddenText>
              </When>
              <Otherwise>
                <Icon
                  appearance={appearance.iconAppearance}
                  inverse={appearance.inverseIcon}
                  name={this.props.icon.name}
                  group='system'
                  size={3}
                  isResponsive={false}
                />
                {this.props.text}
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </Styled.ButtonWithIcon>
    )
  }
}

export default withTheme(ButtonWithIcon)
