import React, { ReactElement } from 'react'
import { CSSTransition } from 'react-transition-group'

import * as Styled from './styles/Animate.style'

import getMaximumDuration from './utils/getMaximumDuration'

import { AnimateProps } from './Animate.types'

export default ({ show = true, enter, exit, children }: AnimateProps): ReactElement => {
  return (
    <Styled.Animate enter={enter} exit={exit}>
      <CSSTransition
        in={show}
        timeout={{
          enter: getMaximumDuration(enter),
          exit: getMaximumDuration(exit),
        }}
        onEntered={enter.onDone}
        onExited={exit.onDone}
        unmountOnExit
        mountOnEnter
        classNames='animation-container'
      >
        {children}
      </CSSTransition>
    </Styled.Animate>
  )
}
