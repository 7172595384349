import { css, FlattenSimpleInterpolation } from 'styled-components'

import { MixinApplyFocus } from './applyFocus.types'

import createMixin from '../utils/createMixin'

import { Theme } from '../themes/themes.types'

export default createMixin((theme: Theme, disabled: boolean = false): FlattenSimpleInterpolation => {
  if (disabled) {
    return css`
      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none;
      }
    `
  }
  return css`
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
      box-shadow: 0 0 0 3px ${theme.color.focus.default};
    }
  `
}) as MixinApplyFocus
